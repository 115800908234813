.page-error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding-top: 2.5rem;
  padding-bottom: 5rem;
  background-color: $color-edem-green-xdark;

  &.-notfound {
    & > .bg {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 75vw;
      height: 48vw;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../img/folder.png');

      @include bp-mediumonly {
        width: 100vw;
        height: 70vw;
        background-position: left;
        background-image: url('../img/folder-tablet.png');
      }

      @include bp-smallonly {
        width: 100vw;
        height: 118vw;
        background-image: url('../img/folder-mobile.png');
      }

      & > .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        transform: rotate(-2.3deg);
        color: #313131;
        text-align: center;
        padding-right: 2.5rem;
        padding-bottom: 3rem;

        @include bp-mediumonly {
          width: 86%;
        }

        @include bp-smallonly {
          width: 86%;
        }
      }

      & > .info > h1 {
        font-size: 8rem;
        font-weight: 500;
        line-height: 1;
      }

      & > .info > p {
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      & > .info > a {
        font-size: 1.2rem;
        font-weight: bold;
        color: #FFF;
        background-color: #000;
        padding: 1rem 2rem;
        border-radius: .5rem;
      }
    }
  }
}
