%button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 2.5 * $base-unit;
  min-width: 6 * $base-unit;
  padding: 0 1 * $base-unit;
  font: inherit;
  font-size: $font-size-deci;
  font-weight: 500;
  text-align: center;
  line-height: 0.9 * $base-unit;
  appearance: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  user-select: none;
  box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.1),
              0 0 0 calc(5 * #{$base-unit}) rgba(0, 0, 0, 0) inset;
  transition-property: background-color, box-shadow, color;
  transition-duration: 0.2s, 0.2s, 0.3s;
  transition-delay: 0s, 0s, 0.1s;
  transition-timing-function: $swift-out;

  &:hover {
    box-shadow: 1px 4px 5px 1px rgba(0, 0, 0, 0.2),
                inset 0 0 0 calc(5 * #{$base-unit}) rgba(0, 0, 0, 0.1);
  }

  &:active {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0),
                0 0 0 calc(5 * #{$base-unit}) rgba(0, 0, 0, 0.25) inset;
    transition-duration: 0.02s;
  }
}
