/// Google swift out cubic-bezier pattern for transitions and animations
$swift-out: cubic-bezier(.55,0,.1,1);

@keyframes blink {
  0% {opacity: 1;}
  50% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes slideDown {
  0% {transform: translateY(-100%);}
  100% {transform: translateY(0);}
}

.live-blink {
  animation: blink ease-in-out 1.2s infinite both alternate;
}