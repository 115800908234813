.edem-overlay {
  position: fixed;
  opacity: 0;
  width: 0;
  height: 0;
  top: 0;
  background-color: $edem-color-primary;
  z-index: 100000;
  transition: opacity 0.3s ease, width 0s 0.3s, height 0s 0.3s;

  body.-sidebaropen & {
    width: 100%;
    height: 100vh;
    opacity: 0.8;
    cursor: pointer;
    transition: opacity 0.3s 0s ease, width 0s 0s, height 0s 0s;
  }
}