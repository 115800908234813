//Social colors
$facebook-color: #3b5998;
$google-color: #dc4e41;

//e-Democracia default category colors
$documento-color: #b1b1ba;
$adm-publica-color: #5a9ab6;
$agropecuaria-color: #1a7c7b;
$assistencia-social-color: #5d7f1a;
$cidades-color: #657981;
$ciencia-color: #6b78dd;
$comunicacao-color: #0d80a0;
$consumidor-color: #ef4545;
$cultura-color: #c05fba;
$direito-e-justica-color: #1844a3;
$direitos-humanos-color: #a96085;
$economia-color: #2a8945;
$educacao-color: #3abf56;
$esportes-color: #006130;
$familia-color: #f39a35;
$industria-color: #544e4e;
$institucional-color: #22a23d;
$meio-ambiente-color: #c5db9b;
$participacao-e-transparencia-color: #4f69de;
$politica-color: #b0614f;
$previdencia-color: #52c0ca;
$relacoes-exteriores-color: #12b5c4;
$saude-color: #3c864a;
$seguranca-color: #e6413d;
$trabalho-color: #6a97b3;
$transporte-e-transito-color: #71706a;
$turismo-color: #707a66;
$reforma-politica-color: #417d62;
