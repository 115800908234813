.form-field {
  $input-height: 2 * $base-unit;

  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: $base-unit;
  margin-bottom: 0.5 * $base-unit;

  > .input {
    flex: 1;
    padding: 0 0.5 * $base-unit;
    height: $input-height;
    min-width: 0;
    margin: 0;
    border: 0;
    outline: 0;
    background-color: transparent;
    color: #fff;
    font-size: $font-size-base;
    z-index: 1;

    &:focus {
      background-color: transparent;
      color: #fff;
    }

    &:focus + .label {
      background-color: rgba($edem-color-accent, 0.6);
      color: $edem-color-link;
    }

    &:focus + .label::after {
      background-color: $edem-color-link;
      transform: scaleX(1);
      transition: transform 0.3s 0s $swift-out, background-color 0s ease;
    }

    > .option {
      color: #fff;
      background-color: $edem-color-accent;
      font-weight: 300;
    }
  }

  > .label {
    position: absolute;
    top: 1 * $base-unit;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: $input-height;
    padding: 0 0.5 * $base-unit;
    background-color: rgba($edem-color-accent, 0.3);
    color: rgba(#fff, 0.6);
    font-size: $font-size-base;
    font-weight: 400;
    transition: background-color 0.2s ease, color 0.2s ease;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: transparent;
      transform: scaleX(0);
      transform-origin: center;
      z-index: 2;
      transition: transform 0s 0.3s $swift-out, background-color 0.2s ease;
    }

    > .labeltext {
      line-height: 1 * $base-unit;

      @at-root .-filled#{&} {
        animation: slideOut 0.3s forwards;
      }
    }
  }

  > .action {
    flex: 0 1 auto;
    padding-right: 0.5 * $base-unit;
    color: $edem-color-link;
    font-size: $font-size-mili;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    z-index: 1;
  }

  > .error {
    flex: 1 0 100%;
    margin: 0;
    color: $edem-color-negative;
    font-size: $font-size-centi;
    font-weight: 400;
    margin-top: 0.25 * $base-unit;
  }
}

@keyframes slideOut {
  35% {
    opacity: 0;
    transform: translate3d(1 * $base-unit, 0, 0);
    font-size: $font-size-base;
    letter-spacing: initial;
  }
  50% {
    opacity: 0;
    transform: translate3d(-1 * $base-unit, -1.5 * $base-unit, 0);
    font-size: $font-size-mili;
    letter-spacing: 0.05em;
  }
  100% {
    opacity: 1;
    transform: translate3d(-0.5 * $base-unit, -1.5 * $base-unit, 0);
    text-transform: uppercase;
    font-weight: 700;
    font-size: $font-size-mili;
    letter-spacing: 0.05em;
  }
}
