$color-edem-blue: #3399cc;
$color-edem-red: #ff6655;
$color-edem-green-xlight: #88ddaa;
$color-edem-green: #339966;
$color-edem-green-dark: #228855;
$color-edem-green-xdark: #336655;
$color-edem-green-xxdark: #225544;
$color-edem-green-xxxdark: #114433;
$color-edem-green-xxxxdark: #002222;
$color-edem-yellow: #eecc33;
$color-edem-orange: #ee9933;
