.user-avatar {
  align-self: center;
  width: 6 * $base-unit;
  height: 6 * $base-unit;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  text-indent: -100%;
  white-space: nowrap;
  overflow: hidden;

  &.-small {
    width: 1.375 * $base-unit;
    height: 1.375 * $base-unit;
  }
}