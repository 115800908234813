$edem-color-blue: #3399cc;
$edem-color-red: #ff6655;
$edem-color-green-xlight: #88ddaa;
$edem-color-green: #339966;
$edem-color-green-dark: #228855;
$edem-color-green-xdark: #336655;
$edem-color-green-xxdark: #225544;
$edem-color-green-xxxdark: #114433;
$edem-color-green-xxxxdark: #002222;
$edem-color-yellow: #eecc33;
$edem-color-orange: #ee9933;

$edem-color-primary: $edem-color-green-xxxxdark;
$edem-color-accent: $edem-color-green-xxxdark;
$edem-color-secondary: $edem-color-yellow;
$edem-color-link: #aadd88;
$edem-color-negative: $edem-color-red;
$edem-color-facebook: #3b5998;
$edem-color-google: #ffffff;
