.button {
  font-weight: 600;

  &--facebook {
    background-color: $facebook-color;

    &:hover, & > .button--social__icon {
      background-color: darken($facebook-color, 5%);
    }
  }

  &--google-plus {
    background-color: $google-color;

    &:hover, & > .button--social__icon {
      background-color: darken($google-color, 5%);
    }
  }

  &--social {
    position: relative;
    border: 0;

    & > &__icon, & > &__label {
      display: inline-block;
      vertical-align: middle;
    }

    & > &__icon {
      position: absolute;
      left: 0;
      top: 0;
      width: 3rem;
      height: 100%;
      font-size: 1.25rem;
      line-height: 3rem;
    }

    &__label {
      width: 100%;
      text-align: center;
      padding-left: 2.15rem;
    }
  }

  &--expanded {
    width: 100%;
    margin: 0 0.5rem;
  }

  &--type-override {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    padding: 0;
    margin: 0;
  }

  &--label-override {
    word-break: break-all;
    vertical-align: top !important;
    margin: 0 0 1rem 0 !important;
    // Damn you Foundation for making me use !important :(
  }

}