.edem-button {
  $color-bg: $edem-color-accent;
  $color-fg: #fff;

  @extend %button;
  background-color: transparent;
  color: $color-fg;
  margin: 0 auto;
  margin-bottom: 1 * $base-unit;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
    width: 100%;
    background-color: $color-bg;
    z-index: -1;
    transition: height 0.2s 0s $swift-out;
  }

  &.-negative {
    &::before {
      background-color: $edem-color-negative;
    }
  }

  &.-social {
    padding-left: 3.5 * $base-unit;
    padding-right: 1 * $base-unit;

    @include bp-smallonly {
      padding-left: 3 * $base-unit;
      padding-right: 0.5 * $base-unit;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 2.5 * $base-unit;
      height: 100%;
      background-size: 1.25 * $base-unit;
      background-repeat: no-repeat;
      background-position: center center;
    }

    &.-facebook {
      background-color: $edem-color-facebook;
      color: #fff;
      &::before {
        background-image: url('../img/social-icon-facebook.svg');
        background-color: transparent;
        z-index: 1;
      }
    }

    &.-google {
      background-color: $edem-color-google;
      color: hsl(0, 0, 50%);
      &::before {
        background-image: url('../img/social-icon-google.svg');
        background-color: transparent;
        z-index: 1;
      }
    }
  }

  &.-loading {
    color: transparent;
    cursor: default;
    pointer-events: none;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0),
                0 0 0 0 rgba(0, 0, 0, 0) inset;
    transition: color 0.3s 0s $swift-out;

    &::before {
      height: 0.5 * $base-unit;
      background-color: $edem-color-blue;
      background-repeat: no-repeat;
      background-position: 50% 0%;
      background-size: 100% 100%;
      background-image: linear-gradient($edem-color-blue, $edem-color-blue),
                        linear-gradient($edem-color-red, $edem-color-red),
                        linear-gradient($edem-color-green, $edem-color-green),
                        linear-gradient($edem-color-yellow, $edem-color-yellow);
      background-size: 0% 100%, 0% 100%, 0% 100%, 0% 100%;
      transition: height 0.2s 0.1s $swift-out, background-color 0s 0.8s;
      animation: loading 1.5s 0.3s linear infinite;
    }
  }
}

@keyframes loading {
  0% {
    background-size: 0% 100%, 0% 100%, 0% 100%, 0% 100%;
  }
  25% {
    background-size: 0% 100%, 0% 100%, 0% 100%, 110% 100%;
  }
  50% {
    background-size: 0% 100%, 0% 100%, 110% 100%, 100% 100%;
  }
  75% {
    background-size: 0% 100%, 110% 100%, 100% 100%, 100% 100%;
  }
  100% {
    background-size: 110% 100%, 100% 100%, 100% 100%, 100% 100%;
  }
}
