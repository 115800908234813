// This projects needs NPM so that relative paths below can work
@font-face {
  font-family: "fontastic-labhacker";
  src:url("../fontastic-labhacker/fonts/fontastic-labhacker.eot");
  src:url("../fontastic-labhacker/fonts/fontastic-labhacker.eot?#iefix") format("embedded-opentype"),
    url("../fontastic-labhacker/fonts/fontastic-labhacker.woff") format("woff"),
    url("../fontastic-labhacker/fonts/fontastic-labhacker.ttf") format("truetype"),
    url("../fontastic-labhacker/fonts/fontastic-labhacker.svg#fontastic-labhacker") format("svg");
  font-weight: normal;
  font-style: normal;
}

// Mixin to insert icons
@mixin icon($icon, $precedence: before) {
  &::#{$precedence} {
    content: map-get($icons, $icon);
    font-family: 'fontastic-labhacker';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    vertical-align: middle;
    color: inherit;
  }
}

// Update this list when adding new characters to the icon font
$icons: (
  500px: "\e000",
  adjust: "\e001",
  adn: "\e002",
  align-center: "\e003",
  align-justify: "\e004",
  align-left: "\e005",
  align-right: "\e006",
  amazon: "\e007",
  ambulance: "\e008",
  anchor: "\e009",
  android: "\e00a",
  angellist: "\e00b",
  angle-double-down: "\e00c",
  angle-double-left: "\e00d",
  angle-double-right: "\e00e",
  angle-double-up: "\e00f",
  angle-down: "\e010",
  angle-left: "\e011",
  angle-right: "\e012",
  angle-up: "\e013",
  apple: "\e014",
  archive: "\e015",
  area-chart: "\e016",
  arrow-circle-down: "\e017",
  arrow-circle-left: "\e018",
  arrow-circle-o-down: "\e019",
  arrow-circle-o-left: "\e01a",
  arrow-circle-o-right: "\e01b",
  arrow-circle-o-up: "\e01c",
  arrow-circle-right: "\e01d",
  arrow-circle-up: "\e01e",
  arrow-down: "\e01f",
  arrow-left: "\e020",
  arrow-right: "\e021",
  arrow-up: "\e022",
  arrows: "\e023",
  arrows-alt: "\e024",
  arrows-h: "\e025",
  arrows-v: "\e026",
  asterisk: "\e027",
  at: "\e028",
  backward: "\e029",
  balance-scale: "\e02a",
  ban: "\e02b",
  bar-chart: "\e02c",
  barcode: "\e02d",
  bars: "\e02e",
  battery-empty: "\e02f",
  battery-full: "\e030",
  battery-half: "\e031",
  battery-quarter: "\e032",
  battery-three-quarters: "\e033",
  bed: "\e034",
  beer: "\e035",
  behance: "\e036",
  behance-square: "\e037",
  bell: "\e038",
  bell-o: "\e039",
  bell-slash: "\e03a",
  bell-slash-o: "\e03b",
  bicycle: "\e03c",
  binoculars: "\e03d",
  birthday-cake: "\e03e",
  bitbucket: "\e03f",
  bitbucket-square: "\e040",
  black-tie: "\e041",
  bold: "\e042",
  bolt: "\e043",
  bomb: "\e044",
  book: "\e045",
  bookmark: "\e046",
  bookmark-o: "\e047",
  briefcase: "\e048",
  btc: "\e049",
  bug: "\e04a",
  building: "\e04b",
  building-o: "\e04c",
  bullhorn: "\e04d",
  bullseye: "\e04e",
  bus: "\e04f",
  buysellads: "\e050",
  calculator: "\e051",
  calendar: "\e052",
  calendar-check-o: "\e053",
  calendar-minus-o: "\e054",
  calendar-o: "\e055",
  calendar-plus-o: "\e056",
  calendar-times-o: "\e057",
  camera: "\e058",
  camera-retro: "\e059",
  car: "\e05a",
  caret-down: "\e05b",
  caret-left: "\e05c",
  caret-right: "\e05d",
  caret-square-o-down: "\e05e",
  caret-square-o-left: "\e05f",
  caret-square-o-right: "\e060",
  caret-square-o-up: "\e061",
  caret-up: "\e062",
  cart-arrow-down: "\e063",
  cart-plus: "\e064",
  cc: "\e065",
  cc-amex: "\e066",
  cc-diners-club: "\e067",
  cc-discover: "\e068",
  cc-jcb: "\e069",
  cc-mastercard: "\e06a",
  cc-paypal: "\e06b",
  cc-stripe: "\e06c",
  cc-visa: "\e06d",
  certificate: "\e06e",
  chain-broken: "\e06f",
  check: "\e070",
  check-circle: "\e071",
  check-circle-o: "\e072",
  check-square: "\e073",
  check-square-o: "\e074",
  chevron-circle-down: "\e075",
  chevron-circle-left: "\e076",
  chevron-circle-right: "\e077",
  chevron-circle-up: "\e078",
  chevron-down: "\e079",
  chevron-left: "\e07a",
  chevron-right: "\e07b",
  chevron-up: "\e07c",
  child: "\e07d",
  chrome: "\e07e",
  circle: "\e07f",
  circle-o: "\e080",
  circle-o-notch: "\e081",
  circle-thin: "\e082",
  clipboard: "\e083",
  clock-o: "\e084",
  clone: "\e085",
  cloud: "\e086",
  cloud-download: "\e087",
  cloud-upload: "\e088",
  code: "\e089",
  code-fork: "\e08a",
  codepen: "\e08b",
  coffee: "\e08c",
  cog: "\e08d",
  cogs: "\e08e",
  columns: "\e08f",
  comment: "\e090",
  comment-o: "\e091",
  commenting: "\e092",
  commenting-o: "\e093",
  comments: "\e094",
  comments-o: "\e095",
  compass: "\e096",
  compress: "\e097",
  connectdevelop: "\e098",
  contao: "\e099",
  copyright: "\e09a",
  creative-commons: "\e09b",
  credit-card: "\e09c",
  crop: "\e09d",
  crosshairs: "\e09e",
  css3: "\e09f",
  cube: "\e0a0",
  cubes: "\e0a1",
  cutlery: "\e0a2",
  dashcube: "\e0a3",
  database: "\e0a4",
  delicious: "\e0a5",
  desktop: "\e0a6",
  deviantart: "\e0a7",
  diamond: "\e0a8",
  digg: "\e0a9",
  dot-circle-o: "\e0aa",
  download: "\e0ab",
  dribbble: "\e0ac",
  dropbox: "\e0ad",
  drupal: "\e0ae",
  eject: "\e0af",
  ellipsis-h: "\e0b0",
  ellipsis-v: "\e0b1",
  empire: "\e0b2",
  envelope: "\e0b3",
  envelope-o: "\e0b4",
  envelope-square: "\e0b5",
  eraser: "\e0b6",
  eur: "\e0b7",
  exchange: "\e0b8",
  exclamation: "\e0b9",
  exclamation-circle: "\e0ba",
  exclamation-triangle: "\e0bb",
  expand: "\e0bc",
  expeditedssl: "\e0bd",
  external-link: "\e0be",
  external-link-square: "\e0bf",
  eye: "\e0c0",
  eye-slash: "\e0c1",
  eyedropper: "\e0c2",
  facebook: "\e0c3",
  facebook-official: "\e0c4",
  facebook-square: "\e0c5",
  fast-backward: "\e0c6",
  fast-forward: "\e0c7",
  fax: "\e0c8",
  female: "\e0c9",
  fighter-jet: "\e0ca",
  file: "\e0cb",
  file-archive-o: "\e0cc",
  file-audio-o: "\e0cd",
  file-code-o: "\e0ce",
  file-excel-o: "\e0cf",
  file-image-o: "\e0d0",
  file-o: "\e0d1",
  file-pdf-o: "\e0d2",
  file-powerpoint-o: "\e0d3",
  file-text: "\e0d4",
  file-text-o: "\e0d5",
  file-video-o: "\e0d6",
  file-word-o: "\e0d7",
  files-o: "\e0d8",
  film: "\e0d9",
  filter: "\e0da",
  fire: "\e0db",
  fire-extinguisher: "\e0dc",
  firefox: "\e0dd",
  flag: "\e0de",
  flag-checkered: "\e0df",
  flag-o: "\e0e0",
  flask: "\e0e1",
  flickr: "\e0e2",
  floppy-o: "\e0e3",
  folder: "\e0e4",
  folder-o: "\e0e5",
  folder-open: "\e0e6",
  folder-open-o: "\e0e7",
  font: "\e0e8",
  fonticons: "\e0e9",
  forumbee: "\e0ea",
  forward: "\e0eb",
  foursquare: "\e0ec",
  frown-o: "\e0ed",
  futbol-o: "\e0ee",
  gamepad: "\e0ef",
  gavel: "\e0f0",
  gbp: "\e0f1",
  genderless: "\e0f2",
  get-pocket: "\e0f3",
  gg: "\e0f4",
  gg-circle: "\e0f5",
  gift: "\e0f6",
  git: "\e0f7",
  git-square: "\e0f8",
  github: "\e0f9",
  github-alt: "\e0fa",
  github-square: "\e0fb",
  glass: "\e0fc",
  globe: "\e0fd",
  google: "\e0fe",
  google-plus: "\e0ff",
  google-plus-square: "\e100",
  google-wallet: "\e101",
  graduation-cap: "\e102",
  gratipay: "\e103",
  h-square: "\e104",
  hacker-news: "\e105",
  hand-lizard-o: "\e106",
  hand-o-down: "\e107",
  hand-o-left: "\e108",
  hand-o-right: "\e109",
  hand-o-up: "\e10a",
  hand-paper-o: "\e10b",
  hand-peace-o: "\e10c",
  hand-pointer-o: "\e10d",
  hand-rock-o: "\e10e",
  hand-scissors-o: "\e10f",
  hand-spock-o: "\e110",
  hdd-o: "\e111",
  header: "\e112",
  headphones: "\e113",
  heart: "\e114",
  heart-o: "\e115",
  heartbeat: "\e116",
  history: "\e117",
  home: "\e118",
  hospital-o: "\e119",
  hourglass: "\e11a",
  hourglass-end: "\e11b",
  hourglass-half: "\e11c",
  hourglass-o: "\e11d",
  hourglass-start: "\e11e",
  houzz: "\e11f",
  html5: "\e120",
  i-cursor: "\e121",
  ils: "\e122",
  inbox: "\e123",
  indent: "\e124",
  industry: "\e125",
  info: "\e126",
  info-circle: "\e127",
  inr: "\e128",
  instagram: "\e129",
  internet-explorer: "\e12a",
  ioxhost: "\e12b",
  italic: "\e12c",
  joomla: "\e12d",
  jpy: "\e12e",
  jsfiddle: "\e12f",
  key: "\e130",
  keyboard-o: "\e131",
  krw: "\e132",
  language: "\e133",
  laptop: "\e134",
  lastfm: "\e135",
  lastfm-square: "\e136",
  leaf: "\e137",
  leanpub: "\e138",
  lemon-o: "\e139",
  level-down: "\e13a",
  level-up: "\e13b",
  life-ring: "\e13c",
  lightbulb-o: "\e13d",
  line-chart: "\e13e",
  link: "\e13f",
  linkedin: "\e140",
  linkedin-square: "\e141",
  linux: "\e142",
  list: "\e143",
  list-alt: "\e144",
  list-ol: "\e145",
  list-ul: "\e146",
  location-arrow: "\e147",
  lock: "\e148",
  long-arrow-down: "\e149",
  long-arrow-left: "\e14a",
  long-arrow-right: "\e14b",
  long-arrow-up: "\e14c",
  magic: "\e14d",
  magnet: "\e14e",
  male: "\e14f",
  map: "\e150",
  map-marker: "\e151",
  map-o: "\e152",
  map-pin: "\e153",
  map-signs: "\e154",
  mars: "\e155",
  mars-double: "\e156",
  mars-stroke: "\e157",
  mars-stroke-h: "\e158",
  mars-stroke-v: "\e159",
  maxcdn: "\e15a",
  meanpath: "\e15b",
  medium: "\e15c",
  medkit: "\e15d",
  meh-o: "\e15e",
  mercury: "\e15f",
  microphone: "\e160",
  microphone-slash: "\e161",
  minus: "\e162",
  minus-circle: "\e163",
  minus-square: "\e164",
  minus-square-o: "\e165",
  mobile: "\e166",
  money: "\e167",
  moon-o: "\e168",
  motorcycle: "\e169",
  mouse-pointer: "\e16a",
  music: "\e16b",
  neuter: "\e16c",
  newspaper-o: "\e16d",
  object-group: "\e16e",
  object-ungroup: "\e16f",
  odnoklassniki: "\e170",
  odnoklassniki-square: "\e171",
  opencart: "\e172",
  openid: "\e173",
  opera: "\e174",
  optin-monster: "\e175",
  outdent: "\e176",
  pagelines: "\e177",
  paint-brush: "\e178",
  paper-plane: "\e179",
  paper-plane-o: "\e17a",
  paperclip: "\e17b",
  paragraph: "\e17c",
  pause: "\e17d",
  paw: "\e17e",
  paypal: "\e17f",
  pencil: "\e180",
  pencil-square: "\e181",
  pencil-square-o: "\e182",
  phone: "\e183",
  phone-square: "\e184",
  picture-o: "\e185",
  pie-chart: "\e186",
  pied-piper: "\e187",
  pied-piper-alt: "\e188",
  pinterest: "\e189",
  pinterest-p: "\e18a",
  pinterest-square: "\e18b",
  plane: "\e18c",
  play: "\e18d",
  play-circle: "\e18e",
  play-circle-o: "\e18f",
  plug: "\e190",
  plus: "\e191",
  plus-circle: "\e192",
  plus-square: "\e193",
  plus-square-o: "\e194",
  power-off: "\e195",
  print: "\e196",
  puzzle-piece: "\e197",
  qq: "\e198",
  qrcode: "\e199",
  question: "\e19a",
  question-circle: "\e19b",
  quote-left: "\e19c",
  quote-right: "\e19d",
  random: "\e19e",
  rebel: "\e19f",
  recycle: "\e1a0",
  reddit: "\e1a1",
  reddit-square: "\e1a2",
  refresh: "\e1a3",
  registered: "\e1a4",
  renren: "\e1a5",
  repeat: "\e1a6",
  reply: "\e1a7",
  reply-all: "\e1a8",
  retweet: "\e1a9",
  road: "\e1aa",
  rocket: "\e1ab",
  rss: "\e1ac",
  rss-square: "\e1ad",
  rub: "\e1ae",
  safari: "\e1af",
  scissors: "\e1b0",
  search: "\e1b1",
  search-minus: "\e1b2",
  search-plus: "\e1b3",
  sellsy: "\e1b4",
  server: "\e1b5",
  share: "\e1b6",
  share-alt: "\e1b7",
  share-alt-square: "\e1b8",
  share-square: "\e1b9",
  share-square-o: "\e1ba",
  shield: "\e1bb",
  ship: "\e1bc",
  shirtsinbulk: "\e1bd",
  shopping-cart: "\e1be",
  sign-in: "\e1bf",
  sign-out: "\e1c0",
  signal: "\e1c1",
  simplybuilt: "\e1c2",
  sitemap: "\e1c3",
  skyatlas: "\e1c4",
  skype: "\e1c5",
  slack: "\e1c6",
  sliders: "\e1c7",
  slideshare: "\e1c8",
  smile-o: "\e1c9",
  sort: "\e1ca",
  sort-alpha-asc: "\e1cb",
  sort-alpha-desc: "\e1cc",
  sort-amount-asc: "\e1cd",
  sort-amount-desc: "\e1ce",
  sort-asc: "\e1cf",
  sort-desc: "\e1d0",
  sort-numeric-asc: "\e1d1",
  sort-numeric-desc: "\e1d2",
  soundcloud: "\e1d3",
  space-shuttle: "\e1d4",
  spinner: "\e1d5",
  spoon: "\e1d6",
  spotify: "\e1d7",
  square: "\e1d8",
  square-o: "\e1d9",
  stack-exchange: "\e1da",
  stack-overflow: "\e1db",
  star: "\e1dc",
  star-half: "\e1dd",
  star-half-o: "\e1de",
  star-o: "\e1df",
  steam: "\e1e0",
  steam-square: "\e1e1",
  step-backward: "\e1e2",
  step-forward: "\e1e3",
  stethoscope: "\e1e4",
  sticky-note: "\e1e5",
  sticky-note-o: "\e1e6",
  stop: "\e1e7",
  street-view: "\e1e8",
  strikethrough: "\e1e9",
  stumbleupon: "\e1ea",
  stumbleupon-circle: "\e1eb",
  subscript: "\e1ec",
  subway: "\e1ed",
  suitcase: "\e1ee",
  sun-o: "\e1ef",
  superscript: "\e1f0",
  table: "\e1f1",
  tablet: "\e1f2",
  tachometer: "\e1f3",
  tag: "\e1f4",
  tags: "\e1f5",
  tasks: "\e1f6",
  taxi: "\e1f7",
  television: "\e1f8",
  tencent-weibo: "\e1f9",
  terminal: "\e1fa",
  text-height: "\e1fb",
  text-width: "\e1fc",
  th: "\e1fd",
  th-large: "\e1fe",
  th-list: "\e1ff",
  thumb-tack: "\e200",
  thumbs-down: "\e201",
  thumbs-o-down: "\e202",
  thumbs-o-up: "\e203",
  thumbs-up: "\e204",
  ticket: "\e205",
  times: "\e206",
  times-circle: "\e207",
  times-circle-o: "\e208",
  tint: "\e209",
  toggle-off: "\e20a",
  toggle-on: "\e20b",
  trademark: "\e20c",
  train: "\e20d",
  transgender: "\e20e",
  transgender-alt: "\e20f",
  trash: "\e210",
  trash-o: "\e211",
  tree: "\e212",
  trello: "\e213",
  tripadvisor: "\e214",
  trophy: "\e215",
  truck: "\e216",
  try: "\e217",
  tty: "\e218",
  tumblr: "\e219",
  tumblr-square: "\e21a",
  twitch: "\e21b",
  twitter: "\e21c",
  twitter-square: "\e21d",
  umbrella: "\e21e",
  underline: "\e21f",
  undo: "\e220",
  university: "\e221",
  unlock: "\e222",
  unlock-alt: "\e223",
  upload: "\e224",
  usd: "\e225",
  user: "\e226",
  user-md: "\e227",
  user-plus: "\e228",
  user-secret: "\e229",
  user-times: "\e22a",
  users: "\e22b",
  venus: "\e22c",
  venus-double: "\e22d",
  venus-mars: "\e22e",
  viacoin: "\e22f",
  video-camera: "\e230",
  vimeo: "\e231",
  vimeo-square: "\e232",
  vine: "\e233",
  vk: "\e234",
  volume-down: "\e235",
  volume-off: "\e236",
  volume-up: "\e237",
  weibo: "\e238",
  weixin: "\e239",
  whatsapp: "\e23a",
  wheelchair: "\e23b",
  wifi: "\e23c",
  wikipedia-w: "\e23d",
  windows: "\e23e",
  wordpress: "\e23f",
  wrench: "\e240",
  xing: "\e241",
  xing-square: "\e242",
  y-combinator: "\e243",
  yahoo: "\e244",
  yelp: "\e245",
  youtube: "\e246",
  youtube-play: "\e247",
  youtube-square: "\e248",
  adm-publica: "\61",
  agropecuaria: "\62",
  assistencia-social: "\64",
  cidades: "\65",
  ciencia: "\66",
  comunicacao: "\67",
  consumidor: "\68",
  cultura: "\69",
  direito-e-justica: "\6a",
  direitos-humanos: "\6b",
  documento: "\6c",
  economia: "\6d",
  esportes: "\6f",
  familia: "\70",
  industria: "\71",
  institucional: "\72",
  meio-ambiente: "\73",
  participacao-e-transparencia: "\74",
  politica: "\75",
  previdencia: "\76",
  relacoes-exteriores: "\77",
  saude: "\78",
  seguranca: "\79",
  trabalho: "\7a",
  transporte-e-transito: "\41",
  turismo: "\42",
  hackdash: "\63",
  edemocracia: "\43",
  carrot: "\46",
  lotus: "\47",
  shoes: "\44",
  battle-axe: "\45",
  holy: "\48",
  pacifier: "\49",
  reforma-politica: "\4a",
  educacao: "\6e",
  arte-cultura-informacao: "\4b",
  economia-financas-publicas: "\4c",
  trabalho-emprego: "\4d",
  comercio-consumidor: "\4e",
  direitos-humanos-minorias: "\4f",
  comunicacao-social: "\50",
  agricultura: "\51",
  viacao-transporte: "\52",
  ciencia-tecnologia: "\53",
  justica: "\54",
  esporte-lazer: "\55"
);


// Classes for projects using earlier versions of our font.
// DEPRECATED FOR FUTURE USES.
[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fontastic-labhacker" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-500px:before {
  content: "\e000";
}
.icon-adjust:before {
  content: "\e001";
}
.icon-adn:before {
  content: "\e002";
}
.icon-align-center:before {
  content: "\e003";
}
.icon-align-justify:before {
  content: "\e004";
}
.icon-align-left:before {
  content: "\e005";
}
.icon-align-right:before {
  content: "\e006";
}
.icon-amazon:before {
  content: "\e007";
}
.icon-ambulance:before {
  content: "\e008";
}
.icon-anchor:before {
  content: "\e009";
}
.icon-android:before {
  content: "\e00a";
}
.icon-angellist:before {
  content: "\e00b";
}
.icon-angle-double-down:before {
  content: "\e00c";
}
.icon-angle-double-left:before {
  content: "\e00d";
}
.icon-angle-double-right:before {
  content: "\e00e";
}
.icon-angle-double-up:before {
  content: "\e00f";
}
.icon-angle-down:before {
  content: "\e010";
}
.icon-angle-left:before {
  content: "\e011";
}
.icon-angle-right:before {
  content: "\e012";
}
.icon-angle-up:before {
  content: "\e013";
}
.icon-apple:before {
  content: "\e014";
}
.icon-archive:before {
  content: "\e015";
}
.icon-area-chart:before {
  content: "\e016";
}
.icon-arrow-circle-down:before {
  content: "\e017";
}
.icon-arrow-circle-left:before {
  content: "\e018";
}
.icon-arrow-circle-o-down:before {
  content: "\e019";
}
.icon-arrow-circle-o-left:before {
  content: "\e01a";
}
.icon-arrow-circle-o-right:before {
  content: "\e01b";
}
.icon-arrow-circle-o-up:before {
  content: "\e01c";
}
.icon-arrow-circle-right:before {
  content: "\e01d";
}
.icon-arrow-circle-up:before {
  content: "\e01e";
}
.icon-arrow-down:before {
  content: "\e01f";
}
.icon-arrow-left:before {
  content: "\e020";
}
.icon-arrow-right:before {
  content: "\e021";
}
.icon-arrow-up:before {
  content: "\e022";
}
.icon-arrows:before {
  content: "\e023";
}
.icon-arrows-alt:before {
  content: "\e024";
}
.icon-arrows-h:before {
  content: "\e025";
}
.icon-arrows-v:before {
  content: "\e026";
}
.icon-asterisk:before {
  content: "\e027";
}
.icon-at:before {
  content: "\e028";
}
.icon-backward:before {
  content: "\e029";
}
.icon-balance-scale:before {
  content: "\e02a";
}
.icon-ban:before {
  content: "\e02b";
}
.icon-bar-chart:before {
  content: "\e02c";
}
.icon-barcode:before {
  content: "\e02d";
}
.icon-bars:before {
  content: "\e02e";
}
.icon-battery-empty:before {
  content: "\e02f";
}
.icon-battery-full:before {
  content: "\e030";
}
.icon-battery-half:before {
  content: "\e031";
}
.icon-battery-quarter:before {
  content: "\e032";
}
.icon-battery-three-quarters:before {
  content: "\e033";
}
.icon-bed:before {
  content: "\e034";
}
.icon-beer:before {
  content: "\e035";
}
.icon-behance:before {
  content: "\e036";
}
.icon-behance-square:before {
  content: "\e037";
}
.icon-bell:before {
  content: "\e038";
}
.icon-bell-o:before {
  content: "\e039";
}
.icon-bell-slash:before {
  content: "\e03a";
}
.icon-bell-slash-o:before {
  content: "\e03b";
}
.icon-bicycle:before {
  content: "\e03c";
}
.icon-binoculars:before {
  content: "\e03d";
}
.icon-birthday-cake:before {
  content: "\e03e";
}
.icon-bitbucket:before {
  content: "\e03f";
}
.icon-bitbucket-square:before {
  content: "\e040";
}
.icon-black-tie:before {
  content: "\e041";
}
.icon-bold:before {
  content: "\e042";
}
.icon-bolt:before {
  content: "\e043";
}
.icon-bomb:before {
  content: "\e044";
}
.icon-book:before {
  content: "\e045";
}
.icon-bookmark:before {
  content: "\e046";
}
.icon-bookmark-o:before {
  content: "\e047";
}
.icon-briefcase:before {
  content: "\e048";
}
.icon-btc:before {
  content: "\e049";
}
.icon-bug:before {
  content: "\e04a";
}
.icon-building:before {
  content: "\e04b";
}
.icon-building-o:before {
  content: "\e04c";
}
.icon-bullhorn:before {
  content: "\e04d";
}
.icon-bullseye:before {
  content: "\e04e";
}
.icon-bus:before {
  content: "\e04f";
}
.icon-buysellads:before {
  content: "\e050";
}
.icon-calculator:before {
  content: "\e051";
}
.icon-calendar:before {
  content: "\e052";
}
.icon-calendar-check-o:before {
  content: "\e053";
}
.icon-calendar-minus-o:before {
  content: "\e054";
}
.icon-calendar-o:before {
  content: "\e055";
}
.icon-calendar-plus-o:before {
  content: "\e056";
}
.icon-calendar-times-o:before {
  content: "\e057";
}
.icon-camera:before {
  content: "\e058";
}
.icon-camera-retro:before {
  content: "\e059";
}
.icon-car:before {
  content: "\e05a";
}
.icon-caret-down:before {
  content: "\e05b";
}
.icon-caret-left:before {
  content: "\e05c";
}
.icon-caret-right:before {
  content: "\e05d";
}
.icon-caret-square-o-down:before {
  content: "\e05e";
}
.icon-caret-square-o-left:before {
  content: "\e05f";
}
.icon-caret-square-o-right:before {
  content: "\e060";
}
.icon-caret-square-o-up:before {
  content: "\e061";
}
.icon-caret-up:before {
  content: "\e062";
}
.icon-cart-arrow-down:before {
  content: "\e063";
}
.icon-cart-plus:before {
  content: "\e064";
}
.icon-cc:before {
  content: "\e065";
}
.icon-cc-amex:before {
  content: "\e066";
}
.icon-cc-diners-club:before {
  content: "\e067";
}
.icon-cc-discover:before {
  content: "\e068";
}
.icon-cc-jcb:before {
  content: "\e069";
}
.icon-cc-mastercard:before {
  content: "\e06a";
}
.icon-cc-paypal:before {
  content: "\e06b";
}
.icon-cc-stripe:before {
  content: "\e06c";
}
.icon-cc-visa:before {
  content: "\e06d";
}
.icon-certificate:before {
  content: "\e06e";
}
.icon-chain-broken:before {
  content: "\e06f";
}
.icon-check:before {
  content: "\e070";
}
.icon-check-circle:before {
  content: "\e071";
}
.icon-check-circle-o:before {
  content: "\e072";
}
.icon-check-square:before {
  content: "\e073";
}
.icon-check-square-o:before {
  content: "\e074";
}
.icon-chevron-circle-down:before {
  content: "\e075";
}
.icon-chevron-circle-left:before {
  content: "\e076";
}
.icon-chevron-circle-right:before {
  content: "\e077";
}
.icon-chevron-circle-up:before {
  content: "\e078";
}
.icon-chevron-down:before {
  content: "\e079";
}
.icon-chevron-left:before {
  content: "\e07a";
}
.icon-chevron-right:before {
  content: "\e07b";
}
.icon-chevron-up:before {
  content: "\e07c";
}
.icon-child:before {
  content: "\e07d";
}
.icon-chrome:before {
  content: "\e07e";
}
.icon-circle:before {
  content: "\e07f";
}
.icon-circle-o:before {
  content: "\e080";
}
.icon-circle-o-notch:before {
  content: "\e081";
}
.icon-circle-thin:before {
  content: "\e082";
}
.icon-clipboard:before {
  content: "\e083";
}
.icon-clock-o:before {
  content: "\e084";
}
.icon-clone:before {
  content: "\e085";
}
.icon-cloud:before {
  content: "\e086";
}
.icon-cloud-download:before {
  content: "\e087";
}
.icon-cloud-upload:before {
  content: "\e088";
}
.icon-code:before {
  content: "\e089";
}
.icon-code-fork:before {
  content: "\e08a";
}
.icon-codepen:before {
  content: "\e08b";
}
.icon-coffee:before {
  content: "\e08c";
}
.icon-cog:before {
  content: "\e08d";
}
.icon-cogs:before {
  content: "\e08e";
}
.icon-columns:before {
  content: "\e08f";
}
.icon-comment:before {
  content: "\e090";
}
.icon-comment-o:before {
  content: "\e091";
}
.icon-commenting:before {
  content: "\e092";
}
.icon-commenting-o:before {
  content: "\e093";
}
.icon-comments:before {
  content: "\e094";
}
.icon-comments-o:before {
  content: "\e095";
}
.icon-compass:before {
  content: "\e096";
}
.icon-compress:before {
  content: "\e097";
}
.icon-connectdevelop:before {
  content: "\e098";
}
.icon-contao:before {
  content: "\e099";
}
.icon-copyright:before {
  content: "\e09a";
}
.icon-creative-commons:before {
  content: "\e09b";
}
.icon-credit-card:before {
  content: "\e09c";
}
.icon-crop:before {
  content: "\e09d";
}
.icon-crosshairs:before {
  content: "\e09e";
}
.icon-css3:before {
  content: "\e09f";
}
.icon-cube:before {
  content: "\e0a0";
}
.icon-cubes:before {
  content: "\e0a1";
}
.icon-cutlery:before {
  content: "\e0a2";
}
.icon-dashcube:before {
  content: "\e0a3";
}
.icon-database:before {
  content: "\e0a4";
}
.icon-delicious:before {
  content: "\e0a5";
}
.icon-desktop:before {
  content: "\e0a6";
}
.icon-deviantart:before {
  content: "\e0a7";
}
.icon-diamond:before {
  content: "\e0a8";
}
.icon-digg:before {
  content: "\e0a9";
}
.icon-dot-circle-o:before {
  content: "\e0aa";
}
.icon-download:before {
  content: "\e0ab";
}
.icon-dribbble:before {
  content: "\e0ac";
}
.icon-dropbox:before {
  content: "\e0ad";
}
.icon-drupal:before {
  content: "\e0ae";
}
.icon-eject:before {
  content: "\e0af";
}
.icon-ellipsis-h:before {
  content: "\e0b0";
}
.icon-ellipsis-v:before {
  content: "\e0b1";
}
.icon-empire:before {
  content: "\e0b2";
}
.icon-envelope:before {
  content: "\e0b3";
}
.icon-envelope-o:before {
  content: "\e0b4";
}
.icon-envelope-square:before {
  content: "\e0b5";
}
.icon-eraser:before {
  content: "\e0b6";
}
.icon-eur:before {
  content: "\e0b7";
}
.icon-exchange:before {
  content: "\e0b8";
}
.icon-exclamation:before {
  content: "\e0b9";
}
.icon-exclamation-circle:before {
  content: "\e0ba";
}
.icon-exclamation-triangle:before {
  content: "\e0bb";
}
.icon-expand:before {
  content: "\e0bc";
}
.icon-expeditedssl:before {
  content: "\e0bd";
}
.icon-external-link:before {
  content: "\e0be";
}
.icon-external-link-square:before {
  content: "\e0bf";
}
.icon-eye:before {
  content: "\e0c0";
}
.icon-eye-slash:before {
  content: "\e0c1";
}
.icon-eyedropper:before {
  content: "\e0c2";
}
.icon-facebook:before {
  content: "\e0c3";
}
.icon-facebook-official:before {
  content: "\e0c4";
}
.icon-facebook-square:before {
  content: "\e0c5";
}
.icon-fast-backward:before {
  content: "\e0c6";
}
.icon-fast-forward:before {
  content: "\e0c7";
}
.icon-fax:before {
  content: "\e0c8";
}
.icon-female:before {
  content: "\e0c9";
}
.icon-fighter-jet:before {
  content: "\e0ca";
}
.icon-file:before {
  content: "\e0cb";
}
.icon-file-archive-o:before {
  content: "\e0cc";
}
.icon-file-audio-o:before {
  content: "\e0cd";
}
.icon-file-code-o:before {
  content: "\e0ce";
}
.icon-file-excel-o:before {
  content: "\e0cf";
}
.icon-file-image-o:before {
  content: "\e0d0";
}
.icon-file-o:before {
  content: "\e0d1";
}
.icon-file-pdf-o:before {
  content: "\e0d2";
}
.icon-file-powerpoint-o:before {
  content: "\e0d3";
}
.icon-file-text:before {
  content: "\e0d4";
}
.icon-file-text-o:before {
  content: "\e0d5";
}
.icon-file-video-o:before {
  content: "\e0d6";
}
.icon-file-word-o:before {
  content: "\e0d7";
}
.icon-files-o:before {
  content: "\e0d8";
}
.icon-film:before {
  content: "\e0d9";
}
.icon-filter:before {
  content: "\e0da";
}
.icon-fire:before {
  content: "\e0db";
}
.icon-fire-extinguisher:before {
  content: "\e0dc";
}
.icon-firefox:before {
  content: "\e0dd";
}
.icon-flag:before {
  content: "\e0de";
}
.icon-flag-checkered:before {
  content: "\e0df";
}
.icon-flag-o:before {
  content: "\e0e0";
}
.icon-flask:before {
  content: "\e0e1";
}
.icon-flickr:before {
  content: "\e0e2";
}
.icon-floppy-o:before {
  content: "\e0e3";
}
.icon-folder:before {
  content: "\e0e4";
}
.icon-folder-o:before {
  content: "\e0e5";
}
.icon-folder-open:before {
  content: "\e0e6";
}
.icon-folder-open-o:before {
  content: "\e0e7";
}
.icon-font:before {
  content: "\e0e8";
}
.icon-fonticons:before {
  content: "\e0e9";
}
.icon-forumbee:before {
  content: "\e0ea";
}
.icon-forward:before {
  content: "\e0eb";
}
.icon-foursquare:before {
  content: "\e0ec";
}
.icon-frown-o:before {
  content: "\e0ed";
}
.icon-futbol-o:before {
  content: "\e0ee";
}
.icon-gamepad:before {
  content: "\e0ef";
}
.icon-gavel:before {
  content: "\e0f0";
}
.icon-gbp:before {
  content: "\e0f1";
}
.icon-genderless:before {
  content: "\e0f2";
}
.icon-get-pocket:before {
  content: "\e0f3";
}
.icon-gg:before {
  content: "\e0f4";
}
.icon-gg-circle:before {
  content: "\e0f5";
}
.icon-gift:before {
  content: "\e0f6";
}
.icon-git:before {
  content: "\e0f7";
}
.icon-git-square:before {
  content: "\e0f8";
}
.icon-github:before {
  content: "\e0f9";
}
.icon-github-alt:before {
  content: "\e0fa";
}
.icon-github-square:before {
  content: "\e0fb";
}
.icon-glass:before {
  content: "\e0fc";
}
.icon-globe:before {
  content: "\e0fd";
}
.icon-google:before {
  content: "\e0fe";
}
.icon-google-plus:before {
  content: "\e0ff";
}
.icon-google-plus-square:before {
  content: "\e100";
}
.icon-google-wallet:before {
  content: "\e101";
}
.icon-graduation-cap:before {
  content: "\e102";
}
.icon-gratipay:before {
  content: "\e103";
}
.icon-h-square:before {
  content: "\e104";
}
.icon-hacker-news:before {
  content: "\e105";
}
.icon-hand-lizard-o:before {
  content: "\e106";
}
.icon-hand-o-down:before {
  content: "\e107";
}
.icon-hand-o-left:before {
  content: "\e108";
}
.icon-hand-o-right:before {
  content: "\e109";
}
.icon-hand-o-up:before {
  content: "\e10a";
}
.icon-hand-paper-o:before {
  content: "\e10b";
}
.icon-hand-peace-o:before {
  content: "\e10c";
}
.icon-hand-pointer-o:before {
  content: "\e10d";
}
.icon-hand-rock-o:before {
  content: "\e10e";
}
.icon-hand-scissors-o:before {
  content: "\e10f";
}
.icon-hand-spock-o:before {
  content: "\e110";
}
.icon-hdd-o:before {
  content: "\e111";
}
.icon-header:before {
  content: "\e112";
}
.icon-headphones:before {
  content: "\e113";
}
.icon-heart:before {
  content: "\e114";
}
.icon-heart-o:before {
  content: "\e115";
}
.icon-heartbeat:before {
  content: "\e116";
}
.icon-history:before {
  content: "\e117";
}
.icon-home:before {
  content: "\e118";
}
.icon-hospital-o:before {
  content: "\e119";
}
.icon-hourglass:before {
  content: "\e11a";
}
.icon-hourglass-end:before {
  content: "\e11b";
}
.icon-hourglass-half:before {
  content: "\e11c";
}
.icon-hourglass-o:before {
  content: "\e11d";
}
.icon-hourglass-start:before {
  content: "\e11e";
}
.icon-houzz:before {
  content: "\e11f";
}
.icon-html5:before {
  content: "\e120";
}
.icon-i-cursor:before {
  content: "\e121";
}
.icon-ils:before {
  content: "\e122";
}
.icon-inbox:before {
  content: "\e123";
}
.icon-indent:before {
  content: "\e124";
}
.icon-industry:before {
  content: "\e125";
}
.icon-info:before {
  content: "\e126";
}
.icon-info-circle:before {
  content: "\e127";
}
.icon-inr:before {
  content: "\e128";
}
.icon-instagram:before {
  content: "\e129";
}
.icon-internet-explorer:before {
  content: "\e12a";
}
.icon-ioxhost:before {
  content: "\e12b";
}
.icon-italic:before {
  content: "\e12c";
}
.icon-joomla:before {
  content: "\e12d";
}
.icon-jpy:before {
  content: "\e12e";
}
.icon-jsfiddle:before {
  content: "\e12f";
}
.icon-key:before {
  content: "\e130";
}
.icon-keyboard-o:before {
  content: "\e131";
}
.icon-krw:before {
  content: "\e132";
}
.icon-language:before {
  content: "\e133";
}
.icon-laptop:before {
  content: "\e134";
}
.icon-lastfm:before {
  content: "\e135";
}
.icon-lastfm-square:before {
  content: "\e136";
}
.icon-leaf:before {
  content: "\e137";
}
.icon-leanpub:before {
  content: "\e138";
}
.icon-lemon-o:before {
  content: "\e139";
}
.icon-level-down:before {
  content: "\e13a";
}
.icon-level-up:before {
  content: "\e13b";
}
.icon-life-ring:before {
  content: "\e13c";
}
.icon-lightbulb-o:before {
  content: "\e13d";
}
.icon-line-chart:before {
  content: "\e13e";
}
.icon-link:before {
  content: "\e13f";
}
.icon-linkedin:before {
  content: "\e140";
}
.icon-linkedin-square:before {
  content: "\e141";
}
.icon-linux:before {
  content: "\e142";
}
.icon-list:before {
  content: "\e143";
}
.icon-list-alt:before {
  content: "\e144";
}
.icon-list-ol:before {
  content: "\e145";
}
.icon-list-ul:before {
  content: "\e146";
}
.icon-location-arrow:before {
  content: "\e147";
}
.icon-lock:before {
  content: "\e148";
}
.icon-long-arrow-down:before {
  content: "\e149";
}
.icon-long-arrow-left:before {
  content: "\e14a";
}
.icon-long-arrow-right:before {
  content: "\e14b";
}
.icon-long-arrow-up:before {
  content: "\e14c";
}
.icon-magic:before {
  content: "\e14d";
}
.icon-magnet:before {
  content: "\e14e";
}
.icon-male:before {
  content: "\e14f";
}
.icon-map:before {
  content: "\e150";
}
.icon-map-marker:before {
  content: "\e151";
}
.icon-map-o:before {
  content: "\e152";
}
.icon-map-pin:before {
  content: "\e153";
}
.icon-map-signs:before {
  content: "\e154";
}
.icon-mars:before {
  content: "\e155";
}
.icon-mars-double:before {
  content: "\e156";
}
.icon-mars-stroke:before {
  content: "\e157";
}
.icon-mars-stroke-h:before {
  content: "\e158";
}
.icon-mars-stroke-v:before {
  content: "\e159";
}
.icon-maxcdn:before {
  content: "\e15a";
}
.icon-meanpath:before {
  content: "\e15b";
}
.icon-medium:before {
  content: "\e15c";
}
.icon-medkit:before {
  content: "\e15d";
}
.icon-meh-o:before {
  content: "\e15e";
}
.icon-mercury:before {
  content: "\e15f";
}
.icon-microphone:before {
  content: "\e160";
}
.icon-microphone-slash:before {
  content: "\e161";
}
.icon-minus:before {
  content: "\e162";
}
.icon-minus-circle:before {
  content: "\e163";
}
.icon-minus-square:before {
  content: "\e164";
}
.icon-minus-square-o:before {
  content: "\e165";
}
.icon-mobile:before {
  content: "\e166";
}
.icon-money:before {
  content: "\e167";
}
.icon-moon-o:before {
  content: "\e168";
}
.icon-motorcycle:before {
  content: "\e169";
}
.icon-mouse-pointer:before {
  content: "\e16a";
}
.icon-music:before {
  content: "\e16b";
}
.icon-neuter:before {
  content: "\e16c";
}
.icon-newspaper-o:before {
  content: "\e16d";
}
.icon-object-group:before {
  content: "\e16e";
}
.icon-object-ungroup:before {
  content: "\e16f";
}
.icon-odnoklassniki:before {
  content: "\e170";
}
.icon-odnoklassniki-square:before {
  content: "\e171";
}
.icon-opencart:before {
  content: "\e172";
}
.icon-openid:before {
  content: "\e173";
}
.icon-opera:before {
  content: "\e174";
}
.icon-optin-monster:before {
  content: "\e175";
}
.icon-outdent:before {
  content: "\e176";
}
.icon-pagelines:before {
  content: "\e177";
}
.icon-paint-brush:before {
  content: "\e178";
}
.icon-paper-plane:before {
  content: "\e179";
}
.icon-paper-plane-o:before {
  content: "\e17a";
}
.icon-paperclip:before {
  content: "\e17b";
}
.icon-paragraph:before {
  content: "\e17c";
}
.icon-pause:before {
  content: "\e17d";
}
.icon-paw:before {
  content: "\e17e";
}
.icon-paypal:before {
  content: "\e17f";
}
.icon-pencil:before {
  content: "\e180";
}
.icon-pencil-square:before {
  content: "\e181";
}
.icon-pencil-square-o:before {
  content: "\e182";
}
.icon-phone:before {
  content: "\e183";
}
.icon-phone-square:before {
  content: "\e184";
}
.icon-picture-o:before {
  content: "\e185";
}
.icon-pie-chart:before {
  content: "\e186";
}
.icon-pied-piper:before {
  content: "\e187";
}
.icon-pied-piper-alt:before {
  content: "\e188";
}
.icon-pinterest:before {
  content: "\e189";
}
.icon-pinterest-p:before {
  content: "\e18a";
}
.icon-pinterest-square:before {
  content: "\e18b";
}
.icon-plane:before {
  content: "\e18c";
}
.icon-play:before {
  content: "\e18d";
}
.icon-play-circle:before {
  content: "\e18e";
}
.icon-play-circle-o:before {
  content: "\e18f";
}
.icon-plug:before {
  content: "\e190";
}
.icon-plus:before {
  content: "\e191";
}
.icon-plus-circle:before {
  content: "\e192";
}
.icon-plus-square:before {
  content: "\e193";
}
.icon-plus-square-o:before {
  content: "\e194";
}
.icon-power-off:before {
  content: "\e195";
}
.icon-print:before {
  content: "\e196";
}
.icon-puzzle-piece:before {
  content: "\e197";
}
.icon-qq:before {
  content: "\e198";
}
.icon-qrcode:before {
  content: "\e199";
}
.icon-question:before {
  content: "\e19a";
}
.icon-question-circle:before {
  content: "\e19b";
}
.icon-quote-left:before {
  content: "\e19c";
}
.icon-quote-right:before {
  content: "\e19d";
}
.icon-random:before {
  content: "\e19e";
}
.icon-rebel:before {
  content: "\e19f";
}
.icon-recycle:before {
  content: "\e1a0";
}
.icon-reddit:before {
  content: "\e1a1";
}
.icon-reddit-square:before {
  content: "\e1a2";
}
.icon-refresh:before {
  content: "\e1a3";
}
.icon-registered:before {
  content: "\e1a4";
}
.icon-renren:before {
  content: "\e1a5";
}
.icon-repeat:before {
  content: "\e1a6";
}
.icon-reply:before {
  content: "\e1a7";
}
.icon-reply-all:before {
  content: "\e1a8";
}
.icon-retweet:before {
  content: "\e1a9";
}
.icon-road:before {
  content: "\e1aa";
}
.icon-rocket:before {
  content: "\e1ab";
}
.icon-rss:before {
  content: "\e1ac";
}
.icon-rss-square:before {
  content: "\e1ad";
}
.icon-rub:before {
  content: "\e1ae";
}
.icon-safari:before {
  content: "\e1af";
}
.icon-scissors:before {
  content: "\e1b0";
}
.icon-search:before {
  content: "\e1b1";
}
.icon-search-minus:before {
  content: "\e1b2";
}
.icon-search-plus:before {
  content: "\e1b3";
}
.icon-sellsy:before {
  content: "\e1b4";
}
.icon-server:before {
  content: "\e1b5";
}
.icon-share:before {
  content: "\e1b6";
}
.icon-share-alt:before {
  content: "\e1b7";
}
.icon-share-alt-square:before {
  content: "\e1b8";
}
.icon-share-square:before {
  content: "\e1b9";
}
.icon-share-square-o:before {
  content: "\e1ba";
}
.icon-shield:before {
  content: "\e1bb";
}
.icon-ship:before {
  content: "\e1bc";
}
.icon-shirtsinbulk:before {
  content: "\e1bd";
}
.icon-shopping-cart:before {
  content: "\e1be";
}
.icon-sign-in:before {
  content: "\e1bf";
}
.icon-sign-out:before {
  content: "\e1c0";
}
.icon-signal:before {
  content: "\e1c1";
}
.icon-simplybuilt:before {
  content: "\e1c2";
}
.icon-sitemap:before {
  content: "\e1c3";
}
.icon-skyatlas:before {
  content: "\e1c4";
}
.icon-skype:before {
  content: "\e1c5";
}
.icon-slack:before {
  content: "\e1c6";
}
.icon-sliders:before {
  content: "\e1c7";
}
.icon-slideshare:before {
  content: "\e1c8";
}
.icon-smile-o:before {
  content: "\e1c9";
}
.icon-sort:before {
  content: "\e1ca";
}
.icon-sort-alpha-asc:before {
  content: "\e1cb";
}
.icon-sort-alpha-desc:before {
  content: "\e1cc";
}
.icon-sort-amount-asc:before {
  content: "\e1cd";
}
.icon-sort-amount-desc:before {
  content: "\e1ce";
}
.icon-sort-asc:before {
  content: "\e1cf";
}
.icon-sort-desc:before {
  content: "\e1d0";
}
.icon-sort-numeric-asc:before {
  content: "\e1d1";
}
.icon-sort-numeric-desc:before {
  content: "\e1d2";
}
.icon-soundcloud:before {
  content: "\e1d3";
}
.icon-space-shuttle:before {
  content: "\e1d4";
}
.icon-spinner:before {
  content: "\e1d5";
}
.icon-spoon:before {
  content: "\e1d6";
}
.icon-spotify:before {
  content: "\e1d7";
}
.icon-square:before {
  content: "\e1d8";
}
.icon-square-o:before {
  content: "\e1d9";
}
.icon-stack-exchange:before {
  content: "\e1da";
}
.icon-stack-overflow:before {
  content: "\e1db";
}
.icon-star:before {
  content: "\e1dc";
}
.icon-star-half:before {
  content: "\e1dd";
}
.icon-star-half-o:before {
  content: "\e1de";
}
.icon-star-o:before {
  content: "\e1df";
}
.icon-steam:before {
  content: "\e1e0";
}
.icon-steam-square:before {
  content: "\e1e1";
}
.icon-step-backward:before {
  content: "\e1e2";
}
.icon-step-forward:before {
  content: "\e1e3";
}
.icon-stethoscope:before {
  content: "\e1e4";
}
.icon-sticky-note:before {
  content: "\e1e5";
}
.icon-sticky-note-o:before {
  content: "\e1e6";
}
.icon-stop:before {
  content: "\e1e7";
}
.icon-street-view:before {
  content: "\e1e8";
}
.icon-strikethrough:before {
  content: "\e1e9";
}
.icon-stumbleupon:before {
  content: "\e1ea";
}
.icon-stumbleupon-circle:before {
  content: "\e1eb";
}
.icon-subscript:before {
  content: "\e1ec";
}
.icon-subway:before {
  content: "\e1ed";
}
.icon-suitcase:before {
  content: "\e1ee";
}
.icon-sun-o:before {
  content: "\e1ef";
}
.icon-superscript:before {
  content: "\e1f0";
}
.icon-table:before {
  content: "\e1f1";
}
.icon-tablet:before {
  content: "\e1f2";
}
.icon-tachometer:before {
  content: "\e1f3";
}
.icon-tag:before {
  content: "\e1f4";
}
.icon-tags:before {
  content: "\e1f5";
}
.icon-tasks:before {
  content: "\e1f6";
}
.icon-taxi:before {
  content: "\e1f7";
}
.icon-television:before {
  content: "\e1f8";
}
.icon-tencent-weibo:before {
  content: "\e1f9";
}
.icon-terminal:before {
  content: "\e1fa";
}
.icon-text-height:before {
  content: "\e1fb";
}
.icon-text-width:before {
  content: "\e1fc";
}
.icon-th:before {
  content: "\e1fd";
}
.icon-th-large:before {
  content: "\e1fe";
}
.icon-th-list:before {
  content: "\e1ff";
}
.icon-thumb-tack:before {
  content: "\e200";
}
.icon-thumbs-down:before {
  content: "\e201";
}
.icon-thumbs-o-down:before {
  content: "\e202";
}
.icon-thumbs-o-up:before {
  content: "\e203";
}
.icon-thumbs-up:before {
  content: "\e204";
}
.icon-ticket:before {
  content: "\e205";
}
.icon-times:before {
  content: "\e206";
}
.icon-times-circle:before {
  content: "\e207";
}
.icon-times-circle-o:before {
  content: "\e208";
}
.icon-tint:before {
  content: "\e209";
}
.icon-toggle-off:before {
  content: "\e20a";
}
.icon-toggle-on:before {
  content: "\e20b";
}
.icon-trademark:before {
  content: "\e20c";
}
.icon-train:before {
  content: "\e20d";
}
.icon-transgender:before {
  content: "\e20e";
}
.icon-transgender-alt:before {
  content: "\e20f";
}
.icon-trash:before {
  content: "\e210";
}
.icon-trash-o:before {
  content: "\e211";
}
.icon-tree:before {
  content: "\e212";
}
.icon-trello:before {
  content: "\e213";
}
.icon-tripadvisor:before {
  content: "\e214";
}
.icon-trophy:before {
  content: "\e215";
}
.icon-truck:before {
  content: "\e216";
}
.icon-try:before {
  content: "\e217";
}
.icon-tty:before {
  content: "\e218";
}
.icon-tumblr:before {
  content: "\e219";
}
.icon-tumblr-square:before {
  content: "\e21a";
}
.icon-twitch:before {
  content: "\e21b";
}
.icon-twitter:before {
  content: "\e21c";
}
.icon-twitter-square:before {
  content: "\e21d";
}
.icon-umbrella:before {
  content: "\e21e";
}
.icon-underline:before {
  content: "\e21f";
}
.icon-undo:before {
  content: "\e220";
}
.icon-university:before {
  content: "\e221";
}
.icon-unlock:before {
  content: "\e222";
}
.icon-unlock-alt:before {
  content: "\e223";
}
.icon-upload:before {
  content: "\e224";
}
.icon-usd:before {
  content: "\e225";
}
.icon-user:before {
  content: "\e226";
}
.icon-user-md:before {
  content: "\e227";
}
.icon-user-plus:before {
  content: "\e228";
}
.icon-user-secret:before {
  content: "\e229";
}
.icon-user-times:before {
  content: "\e22a";
}
.icon-users:before {
  content: "\e22b";
}
.icon-venus:before {
  content: "\e22c";
}
.icon-venus-double:before {
  content: "\e22d";
}
.icon-venus-mars:before {
  content: "\e22e";
}
.icon-viacoin:before {
  content: "\e22f";
}
.icon-video-camera:before {
  content: "\e230";
}
.icon-vimeo:before {
  content: "\e231";
}
.icon-vimeo-square:before {
  content: "\e232";
}
.icon-vine:before {
  content: "\e233";
}
.icon-vk:before {
  content: "\e234";
}
.icon-volume-down:before {
  content: "\e235";
}
.icon-volume-off:before {
  content: "\e236";
}
.icon-volume-up:before {
  content: "\e237";
}
.icon-weibo:before {
  content: "\e238";
}
.icon-weixin:before {
  content: "\e239";
}
.icon-whatsapp:before {
  content: "\e23a";
}
.icon-wheelchair:before {
  content: "\e23b";
}
.icon-wifi:before {
  content: "\e23c";
}
.icon-wikipedia-w:before {
  content: "\e23d";
}
.icon-windows:before {
  content: "\e23e";
}
.icon-wordpress:before {
  content: "\e23f";
}
.icon-wrench:before {
  content: "\e240";
}
.icon-xing:before {
  content: "\e241";
}
.icon-xing-square:before {
  content: "\e242";
}
.icon-y-combinator:before {
  content: "\e243";
}
.icon-yahoo:before {
  content: "\e244";
}
.icon-yelp:before {
  content: "\e245";
}
.icon-youtube:before {
  content: "\e246";
}
.icon-youtube-play:before {
  content: "\e247";
}
.icon-youtube-square:before {
  content: "\e248";
}
.icon-adm-publica:before {
  content: "\61";
}
.icon-agropecuaria:before {
  content: "\62";
}
.icon-assistencia-social:before {
  content: "\64";
}
.icon-cidades:before {
  content: "\65";
}
.icon-ciencia:before {
  content: "\66";
}
.icon-comunicacao:before {
  content: "\67";
}
.icon-consumidor:before {
  content: "\68";
}
.icon-cultura:before {
  content: "\69";
}
.icon-direito-e-justica:before {
  content: "\6a";
}
.icon-direitos-humanos:before {
  content: "\6b";
}
.icon-documento:before {
  content: "\6c";
}
.icon-economia:before {
  content: "\6d";
}
.icon-esportes:before {
  content: "\6f";
}
.icon-familia:before {
  content: "\70";
}
.icon-industria:before {
  content: "\71";
}
.icon-institucional:before {
  content: "\72";
}
.icon-meio-ambiente:before {
  content: "\73";
}
.icon-participacao-e-transparencia:before {
  content: "\74";
}
.icon-politica:before {
  content: "\75";
}
.icon-previdencia:before {
  content: "\76";
}
.icon-relacoes-exteriores:before {
  content: "\77";
}
.icon-saude:before {
  content: "\78";
}
.icon-seguranca:before {
  content: "\79";
}
.icon-trabalho:before {
  content: "\7a";
}
.icon-transporte-e-transito:before {
  content: "\41";
}
.icon-turismo:before {
  content: "\42";
}
.icon-hackdash:before {
  content: "\63";
}
.icon-edemocracia:before {
  content: "\43";
}
.icon-carrot:before {
  content: "\46";
}
.icon-lotus:before {
  content: "\47";
}
.icon-shoes:before {
  content: "\44";
}
.icon-battle-axe:before {
  content: "\45";
}
.icon-holy:before {
  content: "\48";
}
.icon-pacifier:before {
  content: "\49";
}
.icon-reforma-politica:before {
  content: "\4a";
}
.icon-educacao:before {
  content: "\6e";
}
.icon-arte-cultura-informacao:before {
  content: "\4b";
}
.icon-economia-financas-publicas:before {
  content: "\4c";
}
.icon-trabalho-emprego:before {
  content: "\4d";
}
.icon-comercio-consumidor:before {
  content: "\4e";
}
.icon-direitos-humanos-minorias:before {
  content: "\4f";
}
.icon-comunicacao-social:before {
  content: "\50";
}
.icon-agricultura:before {
  content: "\51";
}
.icon-viacao-transporte:before {
  content: "\52";
}
.icon-ciencia-tecnologia:before {
  content: "\53";
}
.icon-justica:before {
  content: "\54";
}
.icon-esporte-lazer:before {
  content: "\55";
}
